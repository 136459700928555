:root {
  --font-family: "Open Sans", "Poppin", "Helvetica", "Arial", "sans-serif";
  //// background colors
  --color-bg1: #fff;
  --color-bg1-light: var(--color-gray6);
  --color-menu: #fff;
  --color-menu-shadow: rgba(8, 8, 43, 0.06);
  --color-bg2: #fff;
  --color-calenly-loading-bg: #ffffff27;
  --color-modal-backdrop: #fff0;
  --color-smooth-cover: #ffffffbc;
  --color-tooltip-bg: var(--color-gray2);
  --color-sticky-bg-alert: #fdced5;

  --color-force-gray1: #1e1e1f;
  --color-force-gray2: #414244;
  --color-force-gray3: #8b9199;
  --color-force-gray4: #c0c6d0;
  --color-force-gray5: #dde2eb;
  --color-force-gray6: #f5f8fc;

  --color-gray3-gray5: var(--color-gray3);
  --color-gray2-gray4: var(--color-gray2);
  --color-gray2-gray6: var(--color-gray2);
  //////////colors///////////
  --color-gray1: #1e1e1f;
  --color-gray6: #f5f8fc;
  --color-gray5: #dde2eb;
  --color-gray4: #c0c6d0;
  --color-gray3: #8b9199;
  --color-gray2: #414244;
  --color-primary-dark: #d63335;
  --color-primary-light2: #ffe9e9;
  --color-primary-light1: #ff999a;
  --color-primary: #f54e50;
  --color-third: #5663f8;
  --color-warning: #f2c94c;
  --color-success: #5dd891;
  --color-success-dark: #083d1e;
  --color-error: #ff6666;
  //
  --color-accent1: #ffd400;
  --color-accent2: #f36dff;
  --color-accent3: #65e591;
  --color-accent4: #ff9a24;
  --color-accent5: #4f4add;
  --color-accent6: #24bdff;
  //
  --max-width-page: 1134px;
}

html[dark="true"] {
  //////////colors///////////
  --color-gray1: #f5f8fc;
  --color-gray6: #1e1e1f;
  --color-gray5: #414244;
  --color-gray3: #c0c6d0;
  --color-gray4: #8b9199;
  --color-gray2: #dde2eb;
  --color-primary-dark: #d63335;
  --color-primary-light2: #d6333540;
  --color-primary-light1: #d6333570;
  --color-primary: #f54e50;
  --color-third: #5663f8;
  --color-warning: #f2c94c;
  --color-success: #5dd891;
  --color-error: #ff6666;
  //
  --color-accent1: #ffd400;
  --color-accent2: #f36dff;
  --color-accent3: #65e591;
  --color-accent4: #ff9a24;
  --color-accent5: #4f4add;
  --color-accent6: #24bdff;
  // new colors for darkmode
  --color-bg1: #181818;
  --color-bg2: #1818187c;
  --color-calenly-loading-bg: #ffffff00;
  --color-modal-backdrop: #fff1;
  --color-smooth-cover: #181818bc;
  --color-menu: var(--color-gray6);
  --color-menu-shadow: rgba(200, 200, 255, 0.06);
  --color-tooltip-bg: var(--color-gray5);
  --color-sticky-bg-alert: #f06868;
  --color-gray3-gray5: var(--color-gray5);
  --color-gray2-gray4: var(--color-gray4);
  --color-gray2-gray6: var(--color-gray6);

  // 
  --swiper-pagination-bullet-inactive-color: var(--color-gray3);

  html, body, #root {
    background-color: var(--color-bg1);
  }
  input {
    caret-color: var(--color-primary-dark);
    color: var(--color-gray2);
  }

  img:not([src*=".svg"]) {
    filter: grayscale(30%);
  }
  
}
