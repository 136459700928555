html,
body {
  height: 100%;
  font-size: 16px;
}

body {
  font-family: var(--font-family);
  min-height: 100%;
  box-sizing: border-box;
  // scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: var(--color-gray4);
  }
  > iframe {
    pointer-events: none;
  }
}
a {
  text-decoration: none;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  list-style: none;
  font-weight: normal;
  font-size: inherit;
  border: none;
  background: transparent;
  color: inherit;
}
