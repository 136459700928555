@import 'theme-variables';
@import 'defaults';
@import 'fonts';
//////////////////////////////////////////
#portal {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999;
}

/* hide spin handle on number typed inputes */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type=number] {
    -moz-appearance: textfield;
}

.bell-notification:hover {
  circle, path {
    fill: var(--color-primary-dark) !important;
  }
}
.swiper-wrapper, .swiper.swiper-initialized {
  z-index: unset !important;
  & > .swiper-slide {
    background: var(--color-bg1) !important;
  }
}
.popup-arrow {
  color: var(--color-tooltip-bg);
}

.tw-fb-svg-icon {
  z-index: 1;
}

#popup-root {
  position: relative;
  z-index: 9999;
}